import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, CanActivateFn } from '@angular/router';

import { map, defaultIfEmpty, catchError, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
// import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '../../shared/services/auth.service';

import { AppPermissionCodes } from '../../constants';

export const authCanActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const router = inject(Router);
	const toastrService = inject(ToastrService);
	// const activeModal = inject(NgbActiveModal);
	const authService = inject(AuthService);

	return authService.getUserProfile$().pipe(
		defaultIfEmpty(undefined), // provide a default value if the observable is empty
		map(user => {
			if (!user) {
				// no user - remember desired route and redirect to login
				toastrService.warning('Please sign in to access this page.', 'Not Signed In');

				authService.redirectUrl = state.url;
				router.navigateByUrl('/login');
				return false;
			}

			// // if navigating to login page while authenticated, redirect to default route
			// console.log('route path:', route.url[0].path);
			// if (route.url[0].path === 'login') {
			// 	console.log('redirecting to default route');
			// 	router.navigateByUrl('/home');
			// 	return false;
			// }

			// user exists - check permissions
			const requiredPermissions = route.data['permissions'] as Array<string>;
			if (!requiredPermissions?.length || requiredPermissions.some(p => authService.hasPermission(p))) {
				// user has required permissions
				return true;
			} else {
				// user does not have required permissions
				// redirect to default route
				// toastrService.warning('You do not have permission to access this page. Redirecting...', 'Not Authorized');

				let route: string;
				if (authService.hasPermission(AppPermissionCodes.Home_Execute)) {
					route = '/home';
				} else if (authService.hasPermission(AppPermissionCodes.Leagues_Execute)) {
					route = '/leagues';
				} else if (authService.hasPermission(AppPermissionCodes.Forum_Execute)) {
					route = '/forum';
				} else if (authService.hasPermission(AppPermissionCodes.Approvals_Execute)) {
					route = '/approvals';
				} else if (authService.hasPermission(AppPermissionCodes.Resources_Execute)) {
					route = '/resources';
				} else if (authService.hasPermission(AppPermissionCodes.Profile_Execute)) {
					route = '/profile';
				} else {
					route = '/login';
				}

				router.navigateByUrl(route);
				return false;
			}
		}),
		catchError(() => {
			toastrService.warning('Authentication error. Please sign in again.', 'Error');

			// activeModal?.dismiss();

			authService.redirectUrl = state.url;
			router.navigateByUrl('/login');
			return of(false);
		})
	);
};
